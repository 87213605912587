import axios from 'axios';
import { Deserializer } from 'jsonapi-serializer';
import { CannotRefreshAccessTokenError } from '../errors/CannotRefreshAccessTokenError';
import { apiUrl } from '../lib/config';
import {
  formatDateTime,
  formatDateTimeUtc,
  getWeekday,
  formatDate,
} from '../lib/utils';

const deserializer = new Deserializer({ keyForAttribute: 'camelCase' });

export class ToolingsClient {
  retryRequestTasks = [];

  authStateListener = [];

  constructor() {
    this.baseURL = apiUrl;
    this.client = axios.create({ baseURL: this.baseURL });

    this.setupClient();
  }

  onAuthStateChange(listener) {
    this.authStateListener.push(listener);

    return () => this.authStateListener.filter(l => listener === l);
  }

  notifyAuthStateListener(token) {
    this.authStateListener.forEach(listener => listener(token));
  }

  /**
   *
   * @param email {string}
   * @param password {string}
   * @returns {Promise<AxiosResponse<any>>}
   */
  async login({ email, password }) {
    return this.client
      .post('/login', { user: { email, password } })
      .then(response => {
        this.accessToken = response.data.data.attributes.token;
        this.refreshToken = response.data.data.attributes.refresh_token;

        return deserializer.deserialize(response.data);
      });
  }

  /**
   *
   * @param {string} email
   * @param {string} password
   * @param {string} company_name
   * @param {string} first_name
   * @param {string} last_name
   * @returns {Promise<*>}
   */
  async register({ email, password, company_name, first_name, last_name }) {
    return this.client
      .post('/register', {
        user: { email, password, company_name, first_name, last_name },
      })
      .then(response => {
        return deserializer.deserialize(response.data);
      });
  }

  async getCompany({ id }) {
    return this.client
      .get(`/companies/${id}`)
      .then(response => deserializer.deserialize(response.data));
  }

  async createProject({
    name,
    logo,
    description,
    color,
    creatorId,
    add_users,
  }) {
    const form = new FormData();

    form.append('use_case[name]', name);
    if (logo) {
      form.append('use_case[logo]', logo);
    }
    if (add_users.length > 0) {
      add_users.forEach(user => {
        form.append('use_case[add_users][]', user);
      });
    }
    form.append('use_case[description]', description);
    form.append('use_case[color]', color);
    form.append(
      'use_case[opportunity_attributes][product_owner_id]',
      creatorId
    );
    form.append(
      'use_case[opportunity_attributes][execution_time]',
      'less_3_months'
    );

    return this.client
      .post('/digital_initiative/use_cases', form)
      .then(response => deserializer.deserialize(response.data));
  }

  async createCompany({ name, logo, description, add_users }) {
    const form = new FormData();

    form.append('company[name]', name);
    if (logo) {
      form.append('company[logo]', logo);
    }
    if (add_users.length > 0) {
      add_users.forEach(user => {
        form.append('company[add_users][][email]', user);
      });
    }
    form.append('company[description]', description);

    return this.client
      .post('/companies', form)
      .then(response => deserializer.deserialize(response.data));
  }

  async createEpic({ projectId, name, color }) {
    return this.client
      .post(`/digital_initiative/use_cases/${projectId}/epics`, {
        epic: { name, color },
      })
      .then(response => deserializer.deserialize(response.data));
  }

  async editEpic({ projectId, epicId, name, color }) {
    return this.client.put(
      `/digital_initiative/use_cases/${projectId}/epics/${epicId}`,
      {
        epic: { name, color },
      }
    );
  }

  async deleteEpic({ projectId, epicId }) {
    return this.client.delete(
      `/digital_initiative/use_cases/${projectId}/epics/${epicId}`
    );
  }

  async createSprint({ projectId, name, goal, range: { start, end }, color }) {
    return this.client
      .post(`/digital_initiative/use_cases/${projectId}/sprints`, {
        sprint: {
          name,
          goal,
          start_date: start.toFormat('yyyy-MM-dd'),
          end_date: end.toFormat('yyyy-MM-dd'),
          color,
        },
      })
      .then(response => deserializer.deserialize(response.data))
      .catch(error => {
        return error.response?.data;
      });
  }

  async editSprint({
    projectId,
    sprintId,
    name,
    goal,
    range: { start, end },
    color,
  }) {
    return this.client
      .put(`/digital_initiative/use_cases/${projectId}/sprints/${sprintId}`, {
        sprint: {
          name,
          goal,
          start_date: start.toFormat('yyyy-MM-dd'),
          end_date: end.toFormat('yyyy-MM-dd'),
          color,
        },
      })
      .then(response => deserializer.deserialize(response.data))
      .catch(error => {
        return error.response?.data;
      });
  }

  async deleteSprint({ projectId, sprintId }) {
    await this.client.delete(
      `/digital_initiative/use_cases/${projectId}/sprints/${sprintId}`
    );
  }

  async createTicket({
    projectId,
    name,
    description,
    status,
    ownerId,
    dueDate,
    point,
    pointMinute,
    images = [],
    sprintIds = [],
    epicId,
    subtasks = [],
    assigneeIds = [],
    tagIds = [],
    startDate,
    endDate,
    recurrence,
    startAt,
    endAt,
  }) {
    const form = new FormData();

    form.append('story[name]', name);
    form.append('story[description]', description);

    if (dueDate) {
      form.append(
        'story[forecast_completion_date]',
        formatDateTime(dueDate, 'yyyy-MM-dd')
      );
    }

    form.append('story[estimation_time]', point);
    form.append('story[estimation_time_minute]', pointMinute);
    form.append('story[story_requester_id]', ownerId);
    form.append('story[story_group_id]', status);
    form.append('story[approval_gate]', 'g1');

    if (startDate) {
      form.append(
        'story[start_at]',
        formatDateTimeUtc(startDate, 'yyyy-LL-dd HH:mm')
      );
    }

    if (endDate) {
      form.append(
        'story[end_at]',
        formatDateTimeUtc(endDate, 'yyyy-LL-dd HH:mm')
      );
    }

    assigneeIds.forEach(assigneeId =>
      form.append('story[story_owner_ids][]', assigneeId)
    );

    if (epicId) {
      form.append('story[epic_id]', epicId);
    }

    if (sprintIds) {
      sprintIds.forEach(sprintId =>
        form.append('story[sprint_ids][]', sprintId)
      );
    }

    if (tagIds) {
      tagIds.forEach(tagId => {
        form.append('story[label_ids][]', tagId);
      });
    }

    subtasks.forEach((subtask, i) => {
      form.append(
        `story[subtasks_attributes][${i}][estimation_time]`,
        subtask.estimation
      );
      form.append(
        `story[subtasks_attributes][${i}][estimation_time_minute]`,
        subtask.estimationTimeMinute
      );
      form.append(`story[subtasks_attributes][${i}][status]`, subtask.status);
      form.append(`story[subtasks_attributes][${i}][name]`, subtask.title);

      subtask.assignees.forEach(assignee => {
        form.append(
          `story[subtasks_attributes][${i}][user_ids][]`,
          assignee.id
        );
      });
    });

    images.forEach(image => {
      form.append(`story[story_pictures_attributes][][picture]`, image.file);
    });

    if (recurrence && recurrence !== 'not_repeat') {
      form.append('recurrence[recurrence]', recurrence);
      form.append(
        'recurrence[wdays]',
        getWeekday(recurrence, dueDate, startAt)
      );

      if (startAt) {
        form.append('recurrence[start_at]', formatDateTime(startAt));
      }
      if (endAt) {
        form.append('recurrence[end_at]', formatDateTime(endAt));
      }
    }

    return this.client
      .post(`/digital_initiative/use_cases/${projectId}/stories`, form)
      .then(response => deserializer.deserialize(response.data));
  }

  async updateTicket(
    id,
    {
      projectId,
      name,
      description,
      status,
      order,
      ownerId,
      dueDate,
      point,
      pointMinute,
      images = [],
      sprintIds = [],
      epicId,
      subtasks = [],
      assigneeIds = [],
      tagIds = [],
      startDate,
      endDate,
      recurrence,
      recurrenceId,
      recurrenceType,
      isUpdatedRecurrence,
      startAt,
      endAt,
      isUpdatedImage,
      isUpdatedSubtask,
      isUpdatedRecurrenceStatus,
      isUpdatedSprintIds,
      isUpdatedTagIds,
      isUpdatedAssigneeIds,
    }
  ) {
    const form = new FormData();

    if (isDefined(name)) {
      form.append('story[name]', name);
    }

    if (isDefined(description)) {
      form.append('story[description]', description);
    }

    form.append(
      'story[forecast_completion_date]',
      dueDate ? formatDateTime(dueDate, 'yyyy-MM-dd') : null
    );

    if (isDefined(point)) {
      form.append('story[estimation_time]', point);
    }

    if (isDefined(pointMinute)) {
      form.append('story[estimation_time_minute]', pointMinute);
    }

    if (isDefined(ownerId)) {
      form.append('story[story_requester_id]', ownerId);
    }

    if (isDefined(status)) {
      form.append('story[story_group_id]', status);
    }

    if (isDefined(order)) {
      form.append('story[order]', order);
    }

    if (startDate) {
      form.append(
        'story[start_at]',
        startDate ? formatDateTimeUtc(startDate, 'yyyy-LL-dd HH:mm') : null
      );
    }

    if (endDate) {
      form.append(
        'story[end_at]',
        endDate ? formatDateTimeUtc(endDate, 'yyyy-LL-dd HH:mm') : null
      );
    }

    if (isUpdatedAssigneeIds) {
      if (assigneeIds.length) {
        assigneeIds.forEach(assigneeId => {
          form.append('story[story_owner_ids][]', assigneeId);
        });
      } else {
        form.append('story[story_owner_ids][]', []);
      }
    }

    if (isDefined(images)) {
      images.forEach((image, i) => {
        if (image.file) {
          form.append(
            `story[story_pictures_attributes][${i}][picture]`,
            image.file
          );
        } else if (image.removed) {
          form.append(`story[story_pictures_attributes][${i}][id]`, image.id);
          form.append(`story[story_pictures_attributes][${i}][_destroy]`, true);
        }
      });
    }
    if (epicId) {
      form.append('story[epic_id]', epicId);
    }

    if (isUpdatedSprintIds) {
      if (sprintIds.length) {
        sprintIds.forEach(sprint => {
          form.append('story[sprint_ids][]', sprint);
        });
      } else {
        form.append('story[sprint_ids][]', []);
      }
    }

    if (isUpdatedTagIds) {
      if (tagIds.length) {
        tagIds.forEach(tagId => {
          form.append('story[label_ids][]', tagId);
        });
      } else {
        form.append('story[label_ids][]', '');
      }
    }

    if (isDefined(subtasks)) {
      subtasks.forEach((subtask, i) => {
        if (subtask.id) {
          form.append(`story[subtasks_attributes][${i}][id]`, subtask.id);
        }
        if (subtask.removed) {
          form.append(`story[subtasks_attributes][${i}][_destroy]`, true);
        }

        form.append(
          `story[subtasks_attributes][${i}][estimation_time]`,
          subtask.estimation
        );
        form.append(
          `story[subtasks_attributes][${i}][estimation_time_minute]`,
          subtask.estimationTimeMinute
        );
        form.append(`story[subtasks_attributes][${i}][status]`, subtask.status);
        form.append(`story[subtasks_attributes][${i}][name]`, subtask.title);

        subtask.assignees.forEach(assignee => {
          form.append(
            `story[subtasks_attributes][${i}][user_ids][]`,
            assignee.id
          );
        });
      });
    }

    if (isUpdatedImage) {
      form.append('attributes_change[]', 'pictures');
    }

    if (isUpdatedSubtask) {
      form.append('attributes_change[]', 'subtasks');
    }

    if (isUpdatedRecurrence || isUpdatedRecurrenceStatus) {
      form.append('attributes_change[]', 'recurrence');
    }

    if (recurrenceId && recurrenceType === 'all') {
      form.append('recurrence[id]', recurrenceId);
    }

    if (recurrence !== 'not_repeat') {
      form.append('recurrence[recurrence]', recurrence);
      form.append(
        'recurrence[wdays]',
        getWeekday(recurrence, dueDate, startAt)
      );

      if (startAt) {
        form.append('recurrence[start_at]', formatDateTime(startAt));
      }
      if (endAt) {
        form.append('recurrence[end_at]', formatDateTime(endAt));
      }
    }

    return this.client
      .put(
        `/digital_initiative/use_cases/${projectId}/stories/${id}?target=${recurrenceType}`,
        form
      )
      .then(response => deserializer.deserialize(response.data));
  }

  async updateTicketStatus(id, { projectId, status, order }) {
    return this.client
      .put(`/digital_initiative/use_cases/${projectId}/stories/${id}`, {
        story: { story_group_id: status, order },
      })
      .then(response => deserializer.deserialize(response.data));
  }

  async acceptInvitation({ companyId, token, id }) {
    return this.client
      .post(
        '/accept_invitation',
        {
          company_id: companyId,
          refresh_token: token,
        },
        {
          params: {
            id,
          },
        }
      )
      .then(response => deserializer.deserialize(response.data));
  }

  async updateProject({
    id,
    name,
    logo,
    description,
    color,
    add_users,
    remove_users,
    hasImageDelete,
  }) {
    const form = new FormData();

    form.append('use_case[name]', name);

    if (logo) {
      form.append('use_case[logo]', logo);
    }
    if (hasImageDelete) {
      form.append('delete_image', true);
    }

    if (add_users.length > 0) {
      add_users.forEach(user => {
        form.append('use_case[add_users][]', user);
      });
    }

    if (remove_users.length > 0) {
      remove_users.forEach(user => {
        form.append('use_case[remove_users][]', user);
      });
    }

    form.append('use_case[description]', description);
    form.append('use_case[color]', color);

    return this.client
      .put(`/digital_initiative/use_cases/${id}`, form)
      .then(response => deserializer.deserialize(response.data));
  }

  async deleteProject(projectId) {
    return this.client.delete(`/digital_initiative/use_cases/${projectId}`);
  }

  async listCompanyMembers({
    companyId,
    per_page = 1000,
    page = 1,
    query = '',
    status = '',
    order,
    sort,
  } = {}) {
    return this.client
      .get(`/companies/${companyId}/users`, {
        params: {
          per_page,
          page,
          status,
          ...(order ? { order } : {}),
          ...(sort ? { sort } : {}),
          ...(query ? { query } : {}),
        },
      })
      .then(async response => {
        const data = await deserializer.deserialize(response.data);

        return {
          data,
          meta: {
            ...response.data.meta,
            pagination: {
              ...response.data.meta.pagination,
              total: per_page * response.data.meta.pagination.total_pages,
            },
          },
        };
      });
  }

  async changeRoleMember({ companyId, roleId, memberId }) {
    return this.client
      .put(`/companies/${companyId}/users/${memberId}`, {
        role: roleId,
      })
      .then(response => deserializer.deserialize(response.data));
  }

  async updateCompany(id, { name, description, logoFile, hasImageDelete }) {
    const form = new FormData();

    if (logoFile) {
      form.append('company[logo]', logoFile);
    }
    if (hasImageDelete) {
      form.append('delete_image', true);
    }
    form.append('company[name]', name);
    form.append('company[description]', description);

    return this.client
      .put(`/companies/${id}`, form)
      .then(response => deserializer.deserialize(response.data));
  }

  async deleteCompany(id) {
    return this.client.delete(`/companies/${id}`);
  }

  async confirmEmail({ id, token }) {
    return this.client.put(`confirm_email?id=${id}&refresh_token=${token}`);
  }

  async updateUserProfile({ id, token, firstName, lastName, password }) {
    return this.client.put(`
      update_user?id=${id}&refresh_token=${token}&first_name=${firstName}&lastName=${lastName}&password=${password}
    `);
  }

  async setupAccount({ id, token, firstName, lastName, password }) {
    return this.client.put(`/user_setup/${id}`, {
      user: {
        refresh_token: token,
        password,
        password_confirmation: password,
        first_name: firstName,
        last_name: lastName,
      },
    });
  }

  async updateAccount({
    firstName,
    lastName,
    nickName,
    avatarFile,
    hasImageDelete,
  }) {
    const form = new FormData();

    if (avatarFile) {
      form.append('user[avatar]', avatarFile);
    }
    if (hasImageDelete) {
      form.append('delete_image', true);
    }
    form.append('user[first_name]', firstName);
    form.append('user[last_name]', lastName);
    form.append('user[nick_name]', nickName);

    return this.client
      .put('/user_update', form)
      .then(response => deserializer.deserialize(response.data));
  }

  async logout() {
    return this.client.delete('/logout').then(() => {
      this.accessToken = null;
      this.refreshToken = null;
    });
  }

  async listProjectMember({ projectId, query, page = 1, perPage = 1000 }) {
    const response = await this.client.get(
      `/digital_initiative/use_cases/${projectId}/users`,
      { params: { query, per_page: perPage, page } }
    );

    const data = await deserializer.deserialize(response.data);
    return {
      data,
      meta: response.data.meta,
    };
  }

  async listProjects({
    per_page = 1000,
    only_active = 1,
    page = 1,
    query = '',
  } = {}) {
    const response = await this.client.get('/digital_initiative/use_cases', {
      params: {
        per_page,
        only_active,
        page,
        ...(query ? { query } : null),
      },
    });

    const data = await deserializer.deserialize(response.data);
    return {
      data,
      meta: response.data.meta,
    };
  }

  async listMembers({ per_page = 1000, page = 1, query = '' } = {}) {
    const response = await this.client.get('/users', {
      params: {
        per_page,
        page,
        ...(query ? { query } : null),
      },
    });

    const data = await deserializer.deserialize(response.data);
    return {
      data,
      meta: response.data.meta,
    };
  }

  async listStarredProjects({ per_page = 1000, page = 1, query = '' } = {}) {
    const response = await this.client.get('/digital_initiative/favorites', {
      params: {
        per_page,
        page,
        ...(query ? { query } : null),
      },
    });

    const data = await deserializer.deserialize(response.data);
    return {
      data,
      meta: response.data.meta,
    };
  }

  async createStarredProject(id) {
    const response = await this.client.post(`/digital_initiative/favorites`, {
      use_case_id: id,
    });

    return deserializer.deserialize(response.data);
  }

  async deleteStarredProject(id) {
    const response = await this.client.delete(
      `/digital_initiative/favorites/${id}`
    );

    return deserializer.deserialize(response.data);
  }

  async findUserByEmail({ email, companyId }) {
    const response = await this.client.get(
      `/companies/${companyId}/users/find_email`,
      {
        params: { email },
      }
    );

    return response;
  }

  // salary api
  async getCompanySalaries(id, { query }) {
    const response = await this.client.get(
      `/companies/${id}/employee_salaries`,
      {
        params: {
          ...(query ? { query } : null),
        },
      }
    );

    const salaryDeserializer = new Deserializer({
      keyForAttribute: 'camelCase',
      user: {
        valueForRelationship(relationship) {
          return deserializer.deserialize({
            data: response.data.included.find(
              item =>
                item.id === relationship.id && item.type === relationship.type
            ),
          });
        },
      },
    });

    return response.data ? salaryDeserializer.deserialize(response.data) : [];
  }

  async getCompanyEmployeesWithNoSalaryData(id) {
    const response = await this.client.get(
      `/companies/${id}/employee_salaries/remaining_users`
    );

    return deserializer.deserialize(response.data);
  }

  async createCompanySalary(id, form) {
    const formData = new FormData();
    formData.append('employee_salary[start_date]', form.startDate ?? null);
    formData.append('employee_salary[position]', form.position ?? null);
    formData.append('employee_salary[level]', form.level ?? null);
    formData.append('employee_salary[user_id]', form.userId ?? null);
    formData.append('employee_salary[salary]', form.salary ?? null);
    return this.client
      .post(`/companies/${id}/employee_salaries`, formData)
      .then(response => deserializer.deserialize(response.data))
      .catch(error => {
        return error.response?.data;
      });
  }

  async updateCompanySalary(companyId, expenseId, form) {
    const formData = new FormData();
    formData.append('employee_salary[start_date]', form.startDate ?? null);
    formData.append('employee_salary[position]', form.position ?? null);
    formData.append('employee_salary[level]', form.level ?? null);
    formData.append('employee_salary[user_id]', form.userId ?? null);
    formData.append('employee_salary[salary]', form.salary ?? null);

    return this.client
      .put(`/companies/${companyId}/employee_salaries/${expenseId}`, formData)
      .then(response => deserializer.deserialize(response.data))
      .catch(error => {
        return error.response?.data;
      });
  }

  async deleteCompanySalary(companyId, expenseId) {
    await this.client.delete(
      `/companies/${companyId}/employee_salaries/${expenseId}`
    );
  }

  // expense api
  async getCompanyExpenseProjects(id) {
    const response = await this.client.get(`/companies/${id}/expense_projects`);
    return deserializer.deserialize(response.data);
  }

  async createCompanyExpenseProject(id, { name }) {
    const formData = new FormData();
    formData.append('expense_project[name]', name);

    return this.client
      .post(`/companies/${id}/expense_projects`, formData)
      .then(response => deserializer.deserialize(response.data))
      .catch(error => {
        return error.response?.data;
      });
  }

  async updateCompanyExpenseProject(companyId, projectId, { name }) {
    const formData = new FormData();
    formData.append('expense_project[name]', name);

    return this.client
      .put(`/companies/${companyId}/expense_projects/${projectId}`, formData)
      .then(response => deserializer.deserialize(response.data))
      .catch(error => {
        return error.response?.data;
      });
  }

  async deleteCompanyExpenseProject(companyId, projectId) {
    return this.client
      .delete(`/companies/${companyId}/expense_projects/${projectId}`)
      .then(response => deserializer.deserialize(response.data))
      .catch(error => {
        return error.response?.data;
      });
  }

  async getCompanyExpenseTypes(id) {
    const response = await this.client.get(`/companies/${id}/expense_types`);

    return deserializer.deserialize(response.data);
  }

  async createCompanyExpenseType(id, { name }) {
    const formData = new FormData();
    formData.append('expense_type[name]', name);

    return this.client
      .post(`/companies/${id}/expense_types`, formData)
      .then(response => deserializer.deserialize(response.data))
      .catch(error => {
        return error.response?.data;
      });
  }

  async updateCompanyExpenseType(companyId, typeId, { name }) {
    const formData = new FormData();
    formData.append('expense_type[name]', name);

    return this.client
      .put(`/companies/${companyId}/expense_types/${typeId}`, formData)
      .then(response => deserializer.deserialize(response.data))
      .catch(error => {
        return error.response?.data;
      });
  }

  async deleteCompanyExpenseType(companyId, typeId) {
    return this.client
      .delete(`/companies/${companyId}/expense_types/${typeId}`)
      .then(response => deserializer.deserialize(response.data))
      .catch(error => {
        return error.response?.data;
      });
  }

  async getCompanyExpenses(
    id,
    {
      team = '',
      per_page = 20,
      page = 1,
      expense_type_id = '',
      expense_project_id = '',
      sort_date = '',
      start_date = '',
      end_date = '',
      query = '',
    }
  ) {
    const response = await this.client.get(`/companies/${id}/expenses`, {
      params: {
        team,
        expense_type_id,
        expense_project_id,
        sort_date,
        start_date,
        end_date,
        per_page,
        page,
        ...(query ? { query } : null),
      },
    });

    const expenseDeserializer = new Deserializer({
      keyForAttribute: 'camelCase',
      expense_type: {
        valueForRelationship(relationship) {
          return deserializer.deserialize({
            data: response.data.included.find(
              item =>
                item.id === relationship.id && item.type === relationship.type
            ),
          });
        },
      },
    });

    return {
      meta: response.data?.meta,
      data: response.data
        ? await expenseDeserializer.deserialize(response.data)
        : [],
    };
  }

  async createCompanyExpense(id, form) {
    const formData = new FormData();
    formData.append('expense[date]', form.date ?? null);
    formData.append('expense[description]', form.description ?? null);
    formData.append('expense[team]', form.team ?? null);
    formData.append('expense[amount]', form.amount ?? null);
    formData.append(
      'expense[company_expense_type_id]',
      form.companyExpenseTypeId ?? null
    );

    formData.append(
      'expense[company_expense_project_id]',
      form.companyExpenseProjectId ?? null
    );

    return this.client
      .post(`/companies/${id}/expenses`, formData)
      .then(response => deserializer.deserialize(response.data))
      .catch(error => {
        return error.response?.data;
      });
  }

  async updateCompanyExpense(companyId, expenseId, form) {
    const formData = new FormData();
    formData.append('expense[date]', form.date ?? null);
    formData.append('expense[description]', form.description ?? null);
    formData.append('expense[team]', form.team ?? null);
    formData.append('expense[amount]', form.amount ?? null);
    formData.append(
      'expense[company_expense_type_id]',
      form.companyExpenseTypeId ?? null
    );

    formData.append(
      'expense[company_expense_project_id]',
      form.companyExpenseProjectId ?? null
    );

    return this.client
      .put(`/companies/${companyId}/expenses/${expenseId}`, formData)
      .then(response => deserializer.deserialize(response.data))
      .catch(error => {
        return error.response?.data;
      });
  }

  async deleteCompanyExpense(companyId, expenseId) {
    await this.client.delete(`/companies/${companyId}/expenses/${expenseId}`);
  }

  // setup project
  async getProjectInfo(id) {
    const response = await this.client.get(
      `/digital_initiative/use_cases/${id}/informations`
    );

    return deserializer.deserialize(response.data);
  }

  async createProjectInfo(id, form) {
    const formData = new FormData();

    formData.append('information[client]', form.clientName ?? '');
    formData.append('information[tin]', form.tin ?? '');
    formData.append('information[company_address]', form.companyAddress ?? '');
    formData.append('information[start_date]', form.startDate ?? null);
    formData.append('information[end_date]', form.endDate ?? null);
    formData.append('information[total_budget_time]', form.totalTime ?? null);

    form.resources.forEach((resource, index) => {
      if (resource.position && resource.position.length > 0) {
        formData.append(
          `information[use_case_resources_attributes][${index}][position]`,
          resource.position
        );
        formData.append(
          `information[use_case_resources_attributes][${index}][number]`,
          resource.number ?? 0
        );
      }
    });

    form.contacts.forEach((contact, index) => {
      if (contact.name && contact.name.length > 0) {
        formData.append(
          `information[use_case_contracts_attributes][${index}][name]`,
          contact.name
        );
        formData.append(
          `information[use_case_contracts_attributes][${index}][role]`,
          contact.role ?? ''
        );
        formData.append(
          `information[use_case_contracts_attributes][${index}][email]`,
          contact.email ?? ''
        );
        formData.append(
          `information[use_case_contracts_attributes][${index}][telephone]`,
          contact.telephone ?? ''
        );
      }
    });

    return this.client
      .post(`/digital_initiative/use_cases/${id}/informations`, formData)
      .then(response => deserializer.deserialize(response.data))
      .catch(error => {
        return error.response?.data;
      });
  }

  async updateProjectInfo(id, form) {
    const formData = new FormData();

    formData.append('information[client]', form.clientName ?? '');
    formData.append('information[tin]', form.tin ?? '');
    formData.append('information[company_address]', form.companyAddress ?? '');
    formData.append('information[start_date]', form.startDate ?? null);
    formData.append('information[end_date]', form.endDate ?? null);
    formData.append('information[total_budget_time]', form.totalTime ?? null);

    form.resources.forEach((resource, index) => {
      if (resource.position && resource.position.length > 0) {
        if (resource.id) {
          formData.append(
            `information[use_case_resources_attributes][${index}][id]`,
            resource.id
          );
          if (resource.destroy) {
            formData.append(
              `information[use_case_resources_attributes][${index}][_destroy]`,
              true
            );
          }
        }
        formData.append(
          `information[use_case_resources_attributes][${index}][position]`,
          resource.position
        );
        formData.append(
          `information[use_case_resources_attributes][${index}][number]`,
          resource.number ?? 0
        );
      }
    });

    form.contacts.forEach((contact, index) => {
      if (contact.name && contact.role) {
        if (contact.id) {
          formData.append(
            `information[use_case_contracts_attributes][${index}][id]`,
            contact.id
          );
          if (contact.destroy) {
            formData.append(
              `information[use_case_contracts_attributes][${index}][_destroy]`,
              true
            );
          }
        }
        formData.append(
          `information[use_case_contracts_attributes][${index}][name]`,
          contact.name ?? ''
        );
        formData.append(
          `information[use_case_contracts_attributes][${index}][role]`,
          contact.role ?? 'person'
        );
        formData.append(
          `information[use_case_contracts_attributes][${index}][email]`,
          contact.email ?? ''
        );
        formData.append(
          `information[use_case_contracts_attributes][${index}][telephone]`,
          contact.telephone ?? ''
        );
      }
    });

    return this.client
      .patch(`/digital_initiative/use_cases/${id}/informations`, formData)
      .then(response => deserializer.deserialize(response.data))
      .catch(error => {
        return error.response?.data;
      });
  }

  async getProject(id) {
    const response = await this.client.get(
      `/digital_initiative/use_cases/${id}`
    );

    return deserializer.deserialize(response.data);
  }

  async getAllTickets({
    query = '',
    excludeRelation = false,
    projectIds = null,
  }) {
    const response = await this.client.get(`/digital_initiative/all_tickets`, {
      params: {
        query,
        use_case_ids: projectIds,
      },
    });

    const projectDeserializer = excludeRelation
      ? deserializer
      : new Deserializer({
          keyForAttribute: 'camelCase',
          story: {
            valueForRelationship(relationship) {
              return deserializer.deserialize({
                data: response.data.included.find(
                  item =>
                    item.id === relationship.id &&
                    item.type === relationship.type
                ),
                included: response.data.included,
              });
            },
          },
        });

    return {
      data: await projectDeserializer.deserialize(response.data),
      meta: response.data?.meta,
    };
  }

  async getAllProjectTickets({ userId }) {
    const response = await this.client.get(
      `/digital_initiative/all_tickets/use_cases`,
      { params: { user_id: userId } }
    );
    return deserializer.deserialize(response.data);
  }

  /**
   *
   * @param {string[]} [projectIds]
   * @param {string} [start_date]
   * @param {string} [end_date]
   * @returns {Promise<*>}
   */
  async getAllTicketsCalendar({
    projectIds = null,
    startDate = '',
    endDate = '',
    excludeRelation = false,
    query = '',
    userId = null,
  }) {
    const response = await this.client.get(
      `/digital_initiative/all_tickets/calendar`,
      {
        params: {
          use_case_ids: projectIds,
          start_date: startDate,
          end_date: endDate,
          query,
          user_id: userId,
        },
      }
    );

    const calendarNonRepeatDeserializer = excludeRelation
      ? deserializer
      : new Deserializer({
          keyForAttribute: 'camelCase',
          story: {
            valueForRelationship(relationship) {
              return deserializer.deserialize({
                data: response.data.included.find(
                  item =>
                    item.id === relationship.id &&
                    item.type === relationship.type
                ),
                included: response.data.included,
              });
            },
          },
        });

    return calendarNonRepeatDeserializer.deserialize(response.data);
  }

  /**
   *
   * @param {string} id
   * @returns {Promise<*>}
   */
  async listProjectTag(id) {
    const response = await this.client.get(
      `/digital_initiative/use_cases/${id}/labels`
    );

    return {
      meta: response.data?.meta,
      data: response.data ? await deserializer.deserialize(response.data) : [],
    };
  }

  async createProjectTag(id, { name, color }) {
    const response = await this.client.post(
      `/digital_initiative/use_cases/${id}/labels`,
      { label: { name, color } }
    );

    return deserializer.deserialize(response.data);
  }

  async updateProjectTag(id, { name, projectId, color }) {
    await this.client.put(
      `/digital_initiative/use_cases/${projectId}/labels/${id}`,
      { label: { name, color } }
    );
  }

  async removeProjectTag(id, { projectId }) {
    await this.client.delete(
      `/digital_initiative/use_cases/${projectId}/labels/${id}`
    );
  }

  /**
   *
   * @param email {string}
   * @returns {Promise<*>}
   */
  async forgotPassword({ email }) {
    return this.client.post('/forget_password', { user: { email } });
  }

  /**
   *
   * @param id {string}
   * @param token {string}
   * @param newPassword {string}
   * @returns {Promise<AxiosResponse<any>>}
   */
  async resetPassword({ id, token, newPassword }) {
    return this.client.put(`/reset_password/${id}`, {
      user: {
        password: newPassword,
        password_confirmation: newPassword,
        refresh_token: token,
      },
    });
  }

  /**
   *
   * @param oldPassword {string}
   * @param newPassword {string}
   * @param newPasswordConfirmation {string}
   * @returns {Promise<AxiosResponse<any>>}
   */
  async changePassword({ oldPassword, newPassword, newPasswordConfirmation }) {
    return this.client.put('/change_password', {
      user: {
        old_password: oldPassword,
        password: newPassword,
        password_confirmation: newPasswordConfirmation,
      },
    });
  }

  /**
   *
   * @param {string[]} [use_case_ids]
   * @param {string[]} [sprint_ids]
   * @param {number} [per_page]
   * @param {number} [page]
   * @returns {Promise<*>}
   */
  async listTickets({
    projectIds = [],
    memberIds = [],
    per_page = 1000,
    page = 1,
    sprintIds = [],
    epicIds = [],
    query = '',
    status = '',
  } = {}) {
    const response = await this.client.get(`/digital_initiative/stories`, {
      params: {
        sprint_ids: sprintIds.join(','),
        use_case_ids: projectIds.join(','),
        story_owner_ids: memberIds.join(','),
        epic_ids: epicIds.join(','),
        per_page,
        page,
        status,
        ...(query ? { query } : null),
      },
    });

    const data = await deserializer.deserialize(response.data);

    return {
      data,
    };
  }

  async listProjectColumn(
    projectId,
    {
      memberIds = [],
      per_page = 1000,
      page = 1,
      sprintIds = [],
      epicIds = [],
      tagIds = [],
      query = '',
      status = '',
      excludeRelation = false,
    } = {}
  ) {
    const response = await this.client.get(
      `/digital_initiative/use_cases/${projectId}/story_groups`,
      {
        params: {
          sprint_ids: sprintIds.join(','),
          story_owner_ids: memberIds.join(','),
          epic_ids: epicIds.join(','),
          tag_ids: tagIds.join(','),
          per_page,
          page,
          status,
          exclude_relation: excludeRelation,
          ...(query ? { query } : null),
        },
      }
    );

    const projectDeserializer = excludeRelation
      ? deserializer
      : new Deserializer({
          keyForAttribute: 'camelCase',
          story: {
            valueForRelationship(relationship) {
              return deserializer.deserialize({
                data: response.data.included.find(
                  item =>
                    item.id === relationship.id &&
                    item.type === relationship.type
                ),
                included: response.data.included,
              });
            },
          },
        });

    const data = await projectDeserializer.deserialize(response.data);

    return {
      data,
    };
  }

  async createColumn({ projectId, name }) {
    const response = await this.client.post(
      `/digital_initiative/use_cases/${projectId}/story_groups`,
      {
        story_group: { name },
      }
    );

    const data = await deserializer.deserialize(response.data);

    return data;
  }

  async updateColumn(id, { name, order: column_no, projectId }) {
    const response = await this.client.put(
      `/digital_initiative/use_cases/${projectId}/story_groups/${id}`,
      {
        story_group: { name, column_no },
      }
    );

    const data = await deserializer.deserialize(response.data);

    return data;
  }

  async archiveColumn(id, { projectId }) {
    const response = await this.client.delete(
      `/digital_initiative/use_cases/${projectId}/story_groups/${id}`
    );

    const data = await deserializer.deserialize(response.data);

    return data;
  }

  async getTicket(id) {
    const response = await this.client.get(`/digital_initiative/stories/${id}`);

    const data = await deserializer.deserialize(response.data);

    return data;
  }

  async duplicateTicket(id, { projectId }) {
    const response = await this.client.post(
      `/digital_initiative/use_cases/${projectId}/stories/${id}/duplicate`
    );
    const data = await deserializer.deserialize(response.data);

    return data;
  }

  async archiveTicket(id, { projectId, deleteType }) {
    await this.client.patch(
      `/digital_initiative/use_cases/${projectId}/stories/${id}/archive?target=${deleteType}`
    );
  }

  // DOCUMENT API

  async listDocumentsDefault(projectId) {
    const response = await this.client.get(
      `/digital_initiative/use_cases/${projectId}/documents/default`
    );

    const data = await deserializer.deserialize(response.data);
    return data;
  }

  async listDocuments(
    projectId,
    { per_page = 1000, page = 1, query = '' } = {}
  ) {
    const response = await this.client.get(
      `/digital_initiative/use_cases/${projectId}/documents`,
      {
        params: {
          per_page,
          page,
          ...(query ? { query } : null),
        },
      }
    );

    const data = await deserializer.deserialize(response.data);
    return data;
  }

  async createDocument({
    projectId,
    name,
    description,
    category,
    file,
    documentType,
  }) {
    const form = new FormData();

    form.append('document[name]', name);
    form.append('document[description]', description);
    form.append('document[category]', category);
    form.append('document[document_type]', documentType);

    if (file.file) {
      form.append('document[file]', file.file);
    }

    return this.client
      .post(`/digital_initiative/use_cases/${projectId}/documents`, form)
      .then(response => deserializer.deserialize(response.data));
  }

  async updateDocument(id, { projectId, name, description, category, file }) {
    const form = new FormData();

    form.append('document[name]', name);
    form.append('document[description]', description);
    form.append('document[category]', category);
    if (file.file) {
      form.append('document[file]', file.file);
    }

    return this.client
      .patch(`/digital_initiative/use_cases/${projectId}/documents/${id}`, form)
      .then(response => deserializer.deserialize(response.data));
  }

  async getDocument(id, { projectId }) {
    const response = await this.client.get(
      `/digital_initiative/use_cases/${projectId}/documents/${id}`
    );

    const data = await deserializer.deserialize(response.data);

    return data;
  }

  async deleteDocument(id, { projectId }) {
    await this.client.delete(
      `/digital_initiative/use_cases/${projectId}/documents/${id}`
    );
  }

  async getProfile() {
    const response = await this.client.get(`/profile`);

    const data = await deserializer.deserialize(response.data);

    return data;
  }

  async inviteMember({ email, roleId, companyId }) {
    const response = await this.client.post(`/invite_to_company`, {
      user: { email, company_id: companyId, role: roleId },
    });

    const data = await deserializer.deserialize(response.data);

    return data;
  }

  async inviteToProject({ projectId, email }) {
    await this.client.post(
      `/digital_initiative/use_cases/${projectId}/use_case_invitations`,
      {
        use_case_invitation: { email },
      }
    );
  }

  async removeMember({ memberId, companyId }) {
    const response = await this.client.delete(
      `/companies/${companyId}/users/${memberId}`
    );

    const data = await deserializer.deserialize(response.data);

    return data;
  }

  async selectCompany(id) {
    return this.client
      .post(`/select_company`, {
        company_id: id,
      })

      .then(response => {
        this.accessToken = response.data.data.attributes.token;
        this.refreshToken = response.data.data.attributes.refresh_token;

        this.notifyAuthStateListener(this.accessToken);

        return deserializer.deserialize(response.data);
      });
  }

  async listActivityLogsAndComments({
    id,
    ticketId,
    onlyComment = false,
    limit = null,
    after = null,
  }) {
    const response = await this.client.get(
      `/use_cases/${id}/activity_log_comments`,
      {
        params: {
          story_id: ticketId,
          only_comment: onlyComment,
          limit,
          after,
        },
      }
    );

    let data = await deserializer.deserialize(response.data);
    const activityLogs = await deserializer.deserialize({
      data: response.data.included.filter(
        ({ type }) => type === 'activity_log' || 'comment'
      ),
      included: response.data.included,
    });

    data = data.map(item => ({
      ...item,
      type: response.data.included.filter(
        ({ id: dataId }) => item.loggable.id === dataId
      )[0].type,
      loggable: activityLogs.find(log => log.id === item.loggable.id),
    }));

    return {
      data,
    };
  }

  async createComment({
    projectId,
    ticketId,
    comment,
    mentionIds = [],
    images = [],
  }) {
    const form = new FormData();

    form.append('comment[story_id]', ticketId);
    form.append('comment[body]', comment);

    if (mentionIds) {
      mentionIds.forEach(mentionId =>
        form.append('comment[mention_user_ids][]', mentionId)
      );
    }

    images.forEach(image => {
      form.append(`comment[attachments_attributes][][document]`, image.file);
    });

    await this.client.post(`/use_cases/${projectId}/comments`, form);
  }

  async updateComment({
    projectId,
    commentId,
    comment,
    mentionIds = [],
    images = [],
  }) {
    const form = new FormData();

    form.append('comment[body]', comment);

    images.forEach((image, i) => {
      if (image.file) {
        form.append(`comment[attachments_attributes][][document]`, image.file);
      } else if (image.removed) {
        form.append(`comment[attachments_attributes][${i}][id]`, image.id);
        form.append(`comment[attachments_attributes][${i}][_destroy]`, true);
      }
    });

    if (mentionIds) {
      mentionIds.forEach(mentionId =>
        form.append('comment[mention_user_ids][]', mentionId)
      );
    }

    await this.client.put(
      `/use_cases/${projectId}/comments/${commentId}`,
      form
    );
  }

  async deleteComment({ projectId, commentId }) {
    await this.client.delete(`/use_cases/${projectId}/comments/${commentId}`);
  }

  async listNotification({ limit = null, after = null, filter = null } = {}) {
    const response = await this.client.get(
      '/digital_initiative/notifications',
      {
        params: {
          limit,
          after,
          filter,
        },
      }
    );

    const data = await deserializer.deserialize(response.data);

    return {
      data,
      meta: response.data.meta,
    };
  }

  async readNotifications({ ids = [] } = {}) {
    await this.client.put('/digital_initiative/notifications/read', {
      notification: { ids },
    });
  }

  async readAllNotifications() {
    await this.client.post('/digital_initiative/notifications/read_all');
  }

  // Time tracking related feature is currently removed
  async listTimeTracking(ticketId, { limit = null, after = null } = {}) {
    const response = await this.client.get(
      `/digital_initiative/stories/${ticketId}/working_times`,
      {
        params: {
          limit,
          after,
        },
      }
    );

    const data = await deserializer.deserialize(response.data);

    return data;
  }

  async createTimeTracking({ ticketId, startedAt, stoppedAt }) {
    const response = await this.client.post(
      `/digital_initiative/stories/${ticketId}/working_times`,
      {
        working_time: {
          started_at: startedAt,
          stopped_at: stoppedAt,
        },
      }
    );

    const data = await deserializer.deserialize(response.data);

    return data;
  }

  async updateTimeTracking(id, { ticketId, startedAt, stoppedAt }) {
    const response = await this.client.put(
      `/digital_initiative/stories/${ticketId}/working_times/${id}`,
      {
        working_time: {
          started_at: startedAt,
          stopped_at: stoppedAt,
        },
      }
    );

    const data = await deserializer.deserialize(response.data);

    return data;
  }

  async deleteTimeTracking(id, { ticketId }) {
    const response = await this.client.delete(
      `/digital_initiative/stories/${ticketId}/working_times/${id}`
    );

    const data = await deserializer.deserialize(response.data);

    return data;
  }

  async startTimeTracking({ projectId, ticketId }) {
    await this.client.post(
      `/digital_initiative/use_cases/${projectId}/stories/${ticketId}/start`
    );
  }

  async stopTimeTracking({ projectId, ticketId }) {
    await this.client.patch(
      `/digital_initiative/use_cases/${projectId}/stories/${ticketId}/stop`
    );
  }
  // Time tracking related feature is currently removed

  async changeDueDate({ projectId, ticketId, date, start, end, allDay }) {
    const form = new FormData();
    form.append(
      'story[forecast_completion_date]',
      formatDateTime(new Date(date))
    );

    if (!allDay && start) {
      form.append(
        'story[start_at]',
        formatDate(new Date(start), 'yyyy-LL-dd HH:mm', 0)
      );
    }
    if (!allDay && end) {
      form.append(
        'story[end_at]',
        formatDate(new Date(end), 'yyyy-LL-dd HH:mm', 0)
      );
    }

    await this.client.patch(
      `digital_initiative/use_cases/${projectId}/stories/${ticketId}/change_due_date`,
      form
    );
  }

  async request(config) {
    const configWithAuthorization = this.configWithAuthorization(
      config,
      this.accessToken
    );

    const result = await this.client.request(configWithAuthorization);

    return result;
  }

  setupClient() {
    this.client.interceptors.request.use(config => {
      if (this.accessToken) {
        return this.configWithAuthorization(config, this.accessToken);
      }

      return config;
    });

    this.client.interceptors.response.use(
      response => response,
      error => {
        if (!isAxiosError(error)) {
          return Promise.reject(error);
        }

        if (!this.isAccessTokenExpired(error)) {
          return Promise.reject(error);
        }

        if (!this.isRefreshingAccessToken) {
          this.isRefreshingAccessToken = true;
          this.refreshAccessToken().then(
            this.handleRefreshAccessTokenSuccess.bind(this),
            this.handleRefreshAccessTokenFail.bind(this)
          );
        }

        const retry = this.retry(error);

        return retry;
      }
    );
  }

  isAccessTokenExpired(error) {
    return (
      error.config &&
      error.config.url !== `/login` &&
      error.config.url !== `/refresh_token` &&
      error.response &&
      error.response.status === 401
    );
  }

  async refreshAccessToken() {
    try {
      const form = new FormData();
      form.append('refresh_token', this.refreshToken);
      form.append('company_id', this.currentCompanyId);

      const response = await this.client.request({
        url: '/refresh_token',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: form,
        refreshTokenRequest: true,
      });

      this.accessToken = response.data.data.attributes.token;
    } catch (error) {
      this.accessToken = null;
      this.refreshToken = null;
      this.notifyAuthStateListener(this.accessToken);
      throw new CannotRefreshAccessTokenError();
    }
  }

  retry(error) {
    return new Promise((resolve, reject) => {
      this.retryRequestTasks.push(accessTokenOrError => {
        if (typeof accessTokenOrError !== 'string') {
          reject(accessTokenOrError);
          return;
        }

        const config = { ...error.config };
        config.headers = injectAuthorizationToken(
          error.config.headers,
          accessTokenOrError
        );

        resolve(this.client.request(config));
      });
    });
  }

  configWithAuthorization(config, token) {
    const { headers = {} } = config;

    if (headers.Authorization) {
      return config;
    }

    return {
      ...config,
      headers: injectAuthorizationToken(headers, token),
    };
  }

  retryRequestQueues(accessTokenOrError) {
    this.retryRequestTasks.forEach(queue => queue(accessTokenOrError));

    this.retryRequestTasks = [];
  }

  handleRefreshAccessTokenSuccess() {
    this.isRefreshingAccessToken = false;
    this.retryRequestQueues(this.accessToken);
  }

  handleRefreshAccessTokenFail(error) {
    this.isRefreshingAccessToken = false;
    this.retryRequestQueues(error);
  }

  get currentCompanyId() {
    return localStorage.getItem('currentCompanyId');
  }

  get refreshToken() {
    return localStorage.getItem('rft');
  }

  set refreshToken(token) {
    if (typeof token === 'undefined' || token === null) {
      localStorage.removeItem('rft');
      return;
    }

    localStorage.setItem('rft', token);
  }

  get accessToken() {
    return localStorage.getItem('act');
  }

  set accessToken(token) {
    if (typeof token === 'undefined' || token === null) {
      localStorage.removeItem('act');
      return;
    }

    localStorage.setItem('act', token);
  }
}

function isAxiosError(error) {
  return error.isAxiosError;
}

function injectAuthorizationToken(headers, token) {
  return { ...headers, Authorization: `Bearer ${token}` };
}

function isDefined(value) {
  return value !== null && typeof value !== 'undefined';
}
