import { useEffect, useState, useContext } from 'react';
import { DateTime } from 'luxon';
import { useQuery, useQueryCache } from 'react-query';
import GlobalContext from '../../store/globalContext';
import ProjectContext from '../../store/projectContext';
import { useDebounce, useQueryParams } from '../../lib/hooks';
import { formatDate } from '../../lib/utils';
import { useAppClient } from '../../lib/AppProvider';
import { useProject } from './useProjectSearch';

export const TIME_TACKING_STATUS = {
  NOT_START: 'NOT_START',
  STARTING: 'STARTING',
  PAUSE: 'PAUSE',
};

export function useProjectColumnOptions({ projectId }) {
  const client = useAppClient();
  const { data } = useQuery(
    ['project', 'detail', projectId, 'column-options'],
    () =>
      client
        .listProjectColumn(projectId, { excludeRelation: true })
        .then(response =>
          response.data?.map(item => ({ label: item.name, value: item.id }))
        ),
    { enabled: !!projectId, refetchOnWindowFocus: false }
  );

  return data ?? [];
}

export function useTicketSearch({
  projectId,
  memberIds,
  epicIds,
  sprintIds,
  tagIds,
  status,
  canFetch = false,
}) {
  const client = useAppClient();
  const [queryParams, setQueryParams] = useQueryParams();

  const { updateStatus, duplicate, archive } = useTicketAction();

  const [q, onChangeQ] = useState(queryParams.q ?? '');
  const memoQuery = useDebounce(q, 1000);
  const queryCache = useQueryCache();
  const { data: project } = useProject(projectId);
  const cacheKey = [
    projectId,
    memoQuery,
    memberIds,
    epicIds,
    sprintIds,
    tagIds,
    status,
  ];

  const { data: tickets, refetch: revalidate } = useQuery(
    cacheKey,
    () =>
      client
        .listProjectColumn(projectId, {
          memberIds,
          epicIds,
          sprintIds,
          tagIds,
          query: memoQuery,
          status,
        })
        .then(response => {
          return (response?.data ?? []).map(data => {
            return {
              id: data.id,
              name: data.name,
              data: data.stories.map(story => ({
                ...transformTicketListData(story),
                status: data.id,
                statusname: data.name,
              })),
              order: data.columnNo,
            };
          });
        }),
    { refetchOnWindowFocus: false, enabled: !!projectId && canFetch }
  );

  useEffect(() => {
    setQueryParams({ q: memoQuery });
    // eslint-disable-next-line
  }, [memoQuery]);

  const { projectDispatch } = useContext(ProjectContext);
  return {
    tickets: tickets ?? [],
    loading: !tickets,
    query: q,
    onChangeQuery: onChangeQ,
    revalidate,
    async createColumn(form) {
      const column = await client.createColumn({
        projectId: form.projectId,
        name: form.name,
      });
      projectDispatch({
        type: 'CREATE_COLUMN',
        column: {
          id: column.id,
          name: column.name,
          order: column.columnNo,
          data: [],
        },
      });

      queryCache.invalidateQueries(({ queryKey }) => {
        return queryKey
          .join('/')
          .startsWith(`project/detail/${form.projectId}`);
      });
    },
    async archiveColumn(id, form) {
      await client.archiveColumn(id, { projectId: form.projectId });
      projectDispatch({ type: 'REMOVE_COLUMN', id });
    },
    async updateColumn(id, form) {
      projectDispatch({
        type: 'UPDATE_COLUMN',
        column: { id, name: form.name, order: form.order },
      });
      await client.updateColumn(id, {
        name: form.name,
        projectId: form.projectId,
        order: form.order,
      });
    },
    async updateStatus(id, form, newData) {
      projectDispatch({ type: 'DROP_TICKET', newData });
      await updateStatus(id, {
        status: form.status,
        order: form.order,
        projectId,
      });
    },
    async duplicate(id, idProject) {
      await duplicate(id, { projectId: idProject ?? project.id });
    },
    async archive(id, deleteType, idProject) {
      await archive(id, { projectId: idProject ?? project.id, deleteType });
    },
    onSelectedTicket({ selectedTicketId, selectedIdEachGroup }) {
      setQueryParams({
        selectedTicketId,
        selectedIdEachGroup,
      });
    },
    onCloseTicket() {
      setQueryParams({
        selectedTicketId: null,
        modal: null,
        defaultDate: null,
        selectedColumnId: null,
        selectedIdEachGroup: null,
      });
    },
    onAddTicket() {
      setQueryParams({ modal: 'create-ticket' });
    },
    onAddTicketWithColumn(selectedColumnId) {
      setQueryParams({ selectedColumnId });
    },
  };
}

export function useTicketAction() {
  const client = useAppClient();
  const { globalState } = useContext(GlobalContext);
  const { projectDispatch } = useContext(ProjectContext);
  const data = globalState.profile;
  return {
    async create({
      projectId,
      name,
      description,
      status,
      dueDate,
      point,
      pointMinute,
      images,
      epicId,
      sprintIds,
      subtasks,
      assignees,
      tags,
      startDate,
      endDate,
      recurrence,
      startAt,
      endAt,
    }) {
      let ticket = await client.createTicket({
        projectId,
        name,
        description,
        status,
        ownerId: data?.id,
        dueDate: recurrence === 'not_repeat' ? dueDate : undefined,
        point,
        pointMinute,
        images,
        epicId,
        sprintIds,
        subtasks: subtasks.map(subtask => ({
          ...subtask,
          status: subtask.isDone ? 'done' : 'todo',
        })),
        assigneeIds: assignees.map(({ id }) => id),
        tagIds: tags.map(({ id }) => id),
        startDate: startDate
          ? formatDate(startDate, 'yyyy-MM-dd HH:mm', -7)
          : undefined,
        endDate: endDate
          ? formatDate(endDate, 'yyyy-MM-dd HH:mm', -7)
          : undefined,
        recurrence,
        startAt,
        endAt,
      });

      ticket = {
        ...ticket,
        epic: ticket?.epic?.name,
        sprints: (ticket?.sprints ?? []).map(sprint => sprint.name),
        hasRecurrence: recurrence !== 'not_repeat',
        storyGroup: {
          id: status,
        },
        // startAt: ticket?.startAt || undefined,
        // endAt: ticket?.endAt || undefined,
      };
      projectDispatch({
        type: 'CREATE_TICKET',
        ticket: transformTicketListData(ticket),
      });
    },
    async update(
      id,
      {
        projectId,
        name,
        description,
        status,
        order,
        dueDate,
        point,
        pointMinute,
        images,
        epicId,
        sprintIds,
        subtasks,
        assignees,
        tags,
        startDate,
        endDate,
        recurrence,
        recurrenceType,
        recurrenceId,
        isUpdatedRecurrence,
        startAt,
        endAt,
        isUpdatedImage,
        isUpdatedSubtask,
        isUpdatedRecurrenceStatus,
        isUpdatedName,
        isUpdatedStatus,
        isUpdatedDescription,
        isUpdatedPoint,
        isUpdatedPointMinute,
        isUpdatedEpicId,
        isUpdatedStartDate,
        isUpdatedEndDate,
        isUpdatedSprintIds,
        isUpdatedTagIds,
        isUpdatedAssigneeIds,
        isUpdateRecurrenceStartAt,
        isUpdateRecurrenceEndAt,
      }
    ) {
      const isUpdated =
        isUpdatedName ||
        isUpdatedRecurrenceStatus ||
        isUpdateRecurrenceStartAt ||
        isUpdateRecurrenceEndAt;
      // desc recurrence status changed update all data
      const updatedTicket = {
        name: isUpdatedName || isUpdated ? name : undefined,
        description:
          isUpdatedDescription || isUpdated ? description : undefined,
        status: isUpdatedStatus || isUpdated ? status : undefined,
        point: isUpdatedPoint || isUpdated ? point : undefined,
        pointMinute:
          isUpdatedPointMinute || isUpdated ? pointMinute : undefined,
        epicId: isUpdatedEpicId || isUpdated ? epicId : undefined,
        sprintIds: isUpdatedSprintIds || isUpdated ? sprintIds : undefined,
        assigneeIds:
          isUpdatedAssigneeIds || isUpdated
            ? assignees.map(assignee => assignee.id)
            : undefined,
        tagIds:
          isUpdatedTagIds || isUpdated ? tags.map(tag => tag.id) : undefined,
        startDate:
          (isUpdatedStartDate || isUpdated) && startDate
            ? formatDate(startDate, 'yyyy-MM-dd HH:mm', -7)
            : undefined,
        endDate:
          (isUpdatedEndDate || isUpdated) && endDate
            ? formatDate(endDate, 'yyyy-MM-dd HH:mm', -7)
            : undefined,
        isUpdatedSprintIds: isUpdatedSprintIds || isUpdated,
        isUpdatedTagIds: isUpdatedTagIds || isUpdated,
        isUpdatedAssigneeIds: isUpdatedAssigneeIds || isUpdated,
      };

      let ticket = await client.updateTicket(id, {
        projectId,
        order,
        ownerId: data?.id,
        dueDate,
        images,
        subtasks: subtasks.map(subtask => ({
          ...subtask,
          status: subtask.isDone ? 'done' : 'todo',
        })),
        recurrence,
        recurrenceType,
        recurrenceId,
        isUpdatedRecurrence,
        startAt,
        endAt,
        isUpdatedImage,
        isUpdatedSubtask,
        isUpdatedRecurrenceStatus,
        ...updatedTicket,
      });
      ticket = {
        ...ticket,
        epic: ticket?.epic?.name,
        sprints: (ticket?.sprints ?? []).map(sprint => sprint.name),
        hasRecurrence: recurrence !== 'not_repeat',
      };
      projectDispatch({
        type: 'UPDATE_TICKET',
        ticket: transformTicketListData(ticket),
      });
    },

    async updateStatus(id, { projectId, status, order }) {
      await client.updateTicketStatus(id, {
        projectId,
        status,
        order,
      });
    },

    async duplicate(id, { projectId }) {
      let ticket = await client.duplicateTicket(id, { projectId });
      ticket = {
        ...ticket,
        epic: ticket?.epic?.name,
        sprints: (ticket?.sprints ?? []).map(sprint => sprint.name),
      };
      projectDispatch({
        type: 'CREATE_TICKET',
        ticket: transformTicketListData(ticket),
      });
    },

    async archive(id, { projectId, deleteType }) {
      await client.archiveTicket(id, { projectId, deleteType });
      projectDispatch({ type: 'REMOVE_TICKET', id });
    },
  };
}

export function transformTicketListData(ticket) {
  return {
    id: ticket.id,
    name: ticket.name,
    order: ticket.order,
    idEachGroup: ticket.idEachGroup,
    description: ticket.description,
    dueDate:
      ticket.forecastCompletionDate &&
      DateTime.fromFormat(ticket.forecastCompletionDate, 'yyyy-MM-dd').setZone(
        'utc+7'
      ),
    point: ticket.estimationTime,
    pointMinute: ticket.estimationTimeMinute,
    userEstimate:
      ticket.userEstimate ??
      ticket.estimationTime * 60 + ticket.estimationTimeMinute,
    hasRecurrence: ticket.hasRecurrence,
    epic: ticket.epic,
    sprints: (ticket?.sprints ?? []).map(sprint => ({
      name: sprint,
    })),
    userCount: ticket.storyOwnerCount,
    assignees: (ticket.storyOwners ?? []).map(owner => ({
      id: owner.id,
      name: owner.fullName,
      avatarUrl: owner.avatarUrl,
      initial: `${owner.firstName.substr(0, 1)}${owner.lastName.substr(0, 1)}`,
    })),
    tags: ticket.labels,
    status: ticket.storyGroup?.id,
    hasSubtask: ticket.hasSubtasks,
    fileCount: ticket.fileCount,
    images:
      ticket.storyPictures?.filter(Boolean).map(({ id, url }) => ({
        id,
        url,
        hash: url,
      })) ?? [],
    storySprints: ticket?.storySprints,
    epicColor: ticket?.epicColor,
  };
}

export function transformTicket(ticket) {
  return {
    id: ticket.id,
    name: ticket.name,
    order: ticket.order,
    idEachGroup: ticket.idEachGroup,
    description: ticket.description,
    dueDate:
      ticket.forecastCompletionDate &&
      DateTime.fromFormat(ticket.forecastCompletionDate, 'yyyy-MM-dd').setZone(
        'utc+7'
      ),
    point: ticket.estimationTime,
    pointMinute: ticket.estimationTimeMinute,
    epic: ticket.epic,
    sprints: (ticket?.sprints ?? []).map(sprint => ({
      id: sprint.id,
      name: sprint.name,
    })),
    assignees: (ticket.storyOwners ?? []).map(owner => ({
      id: owner.id,
      name: owner.fullName,
      avatarUrl: owner.avatarUrl,
      initial: `${owner.firstName.substr(0, 1)}${owner.lastName.substr(0, 1)}`,
    })),
    tags: ticket.labels,
    status: ticket.storyGroup?.id,
    hasSubtask: ticket.hasSubtasks,
    fileCount: ticket.fileCount,
    subtasks:
      ticket.subtasks?.filter(Boolean).map(subtask => ({
        id: subtask.id,
        title: subtask.name,
        estimation: subtask.estimationTime,
        estimationTimeMinute: subtask.estimationTimeMinute,
        isDone: subtask.status === 'done',
        assignees: subtask.users.map(user => ({
          id: user.id,
          name: user.fullName,
          avatarUrl: user.avatarUrl,
          initial: `${user.firstName.substr(0, 1)}${user.lastName.substr(
            0,
            1
          )}`,
        })),
      })) ?? [],
    images:
      ticket.storyPictures?.filter(Boolean).map(({ id, url }) => ({
        id,
        url,
        hash: url,
      })) ?? [],
    startDate: ticket.startAt
      ? DateTime.fromISO(ticket.startAt, { zone: 'utc' })
      : null,
    endDate: ticket?.endAt
      ? DateTime.fromISO(ticket.endAt, { zone: 'utc' })
      : null,
    recurrence: ticket?.storyRecurrence?.recurrence
      ? getRecurrence(ticket?.storyRecurrence)
      : 'not_repeat',
    // repeat: ticket?.storyRecurrence?.repeatEvery ?? null,
    // repeatType: ticket?.storyRecurrence?.repeatEveryType ?? null,
    // monthlyOnDay: ticket?.storyRecurrence?.monthlyOnDay ?? null,
    // monthlyOnWeek: ticket?.storyRecurrence?.monthlyOnWeek ?? null,
    // annuallyAt: ticket?.storyRecurrence?.annuallyAt ?? null,
    // endAt: ticket?.storyRecurrence?.endAt
    //   ? DateTime.fromISO(ticket.storyRecurrence.endAt, { zone: 'utc' })
    //   : null,
    // endOccurrences: ticket?.storyRecurrence?.endOccurrences ?? null,
    recurrenceId: ticket?.storyRecurrence?.id ?? null,
    // repeatOn: ticket?.storyRecurrence
    //   ? getWeekDays({
    //       sunday: ticket?.storyRecurrence.onSunday,
    //       monday: ticket?.storyRecurrence.onMonday,
    //       tuesday: ticket?.storyRecurrence.onTuesday,
    //       wednesday: ticket?.storyRecurrence.onWednesday,
    //       thursday: ticket?.storyRecurrence.onThursday,
    //       friday: ticket?.storyRecurrence.onFriday,
    //       saturday: ticket?.storyRecurrence.onSaturday,
    //     })
    //   : null,
    storySprints: ticket?.storySprints,
    epicColor: ticket?.epicColor,
    startAt: ticket?.storyRecurrence?.startAt || undefined,
    endAt: ticket?.storyRecurrence?.endAt || undefined,
  };
}

export function getRecurrence(ticket) {
  const {
    recurrence,
    // repeatEvery,
    // repeatEveryType,
    // endAt,
    // endOccurrences,
  } = ticket;
  const recurrences = [
    'daily',
    'weekly',
    'monthly',
    'annually',
    'every_weekday',
  ];

  let text = '';

  if (recurrences.includes(recurrence)) {
    text = recurrence;
    // } else if (repeatEvery || repeatEveryType || endAt || endOccurrences) {
    //   text = 'custom';
  } else {
    text = 'not_repeat';
  }

  return text;
}
