import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { theme } from 'styled-tools';
import { Header, SideBarFilter, FilterGroup } from './SideBarFilter';
import { DollarCircleIcon, FolderV2Icon, UserCircleIcon } from './Icons';
import { useAppLayout } from './withAppLayout';
import { usePermissionGuard } from '../features/authentication/hocs/withPermission';

function CompanySidebar({ className, company }) {
  const navigate = useNavigate();
  const location = useLocation();
  const allowSuperAdmin = usePermissionGuard('member-super-admin:write');

  const { isShowSidebar: isShowSidebarDesktop } = useAppLayout();

  return (
    <SideBarFilter
      className={`${className} ${isShowSidebarDesktop ? 'desktop-show' : ''}`}
      showLogo
    >
      <div className="sidebar">
        <Header className="company">
          <div className="project-header">{company.name}</div>
        </Header>
        <FilterGroup>
          <div
            className="menu"
            onClick={() => {
              navigate(`/company/${company.id}`);
            }}
          >
            <FolderV2Icon
              color={
                !location.pathname.includes('expenses') &&
                !location.pathname.includes('salaries')
                  ? '#22c759'
                  : '#9fa3ae'
              }
            />
            <h5
              className="title"
              style={{
                color:
                  !location.pathname.includes('expenses') &&
                  !location.pathname.includes('salaries')
                    ? '#22c759'
                    : '#9fa3ae',
              }}
            >
              Project
            </h5>
          </div>
          {allowSuperAdmin && (
            <div
              className="menu"
              onClick={() => navigate(`/company/${company.id}/expenses`)}
            >
              <DollarCircleIcon
                color={
                  location.pathname.includes('expenses') ? '#22c759' : '#9fa3ae'
                }
              />
              <h5
                className="title"
                style={{
                  color: location.pathname.includes('expenses')
                    ? '#22c759'
                    : '#9fa3ae',
                }}
              >
                Company Expense
              </h5>
            </div>
          )}
          {allowSuperAdmin && (
            <div
              className="menu"
              onClick={() => navigate(`/company/${company.id}/salaries`)}
            >
              <UserCircleIcon
                color={
                  location.pathname.includes('salaries') ? '#22c759' : '#9fa3ae'
                }
              />
              <h5
                className="title"
                style={{
                  color: location.pathname.includes('salaries')
                    ? '#22c759'
                    : '#9fa3ae',
                }}
              >
                Employee
              </h5>
            </div>
          )}
        </FilterGroup>
      </div>
    </SideBarFilter>
  );
}

const StyledCompanySidebar = styled(CompanySidebar)`
  position: fixed;
  top: 0;
  bottom: 0;

  overflow-y: auto;
  transition: all 0.2s ease-in-out;

  border-right: 1px solid #eee;
  height: 100vh;
  width: 0;

  background: white;

  &.desktop-show {
    width: ${theme('sidebarWidth')}px;
    flex: 0 0 ${theme('sidebarWidth')}px;
    z-index: 1;
  }

  > .sidebar {

    > .company {
      font-size: 20px;
      padding-top: 30px;
    }

    > ${FilterGroup} > .menu:not(:first-child) {
      border-top: 2px dashed rgb(238, 238, 238);
    }

    > ${FilterGroup} > .menu {
      cursor: pointer;
      padding: 15px;
      display: flex;

      .title {
        font-size: 14px;
        color: black;
        padding-left: 10px;
        font-weight: bold;
        text-decoration: auto;
      }

        div {
          position: absolute;
          top: 2px;
          right: 5px;
        }
      }
    }

    > ${Header} {
      > .button {
        width: 100%;
      }

      > .project-header {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
      }
    }
  }

  @media (max-width: ${theme('breakpoints.mobile')}px) {
    width: 100% !important;
    display: none;

    &.mobile-show {
      display: block;
    }

    > .content {
      width: 100% !important;
    }
  }
`;

export { StyledCompanySidebar as CompanySidebar };
